// eslint-disable-next-line
export default {
  id: 'coxinha_ninja',
  type: 'ninja',
  title: "Coxinha (Ninja's Version)",
  ingredients: ['x coxinhas congeladas'],
  parts: [
    {
      title: 'Finalização',
      methodOfPreparation: [
        'Coloque as coxinhas com o accessório das grades',
        'Coloque no mode Air fryer em 180 graus por 11 minutos',
      ],
    },
  ],
};
