// eslint-disable-next-line
export default {
  id: 'vegetais',
  type: 'ninja',
  title: 'Vegetais',
  ingredients: [
    '? Sal',
    '? azeite',
    '? pimenta do reino',
    '2 batatas (300g)',
    '2 cenouras (300g)',
  ],
  parts: [
    {
      title: 'Vegetais',
      methodOfPreparation: [
        'Tempere os vegetais com sal, pimenta do reino e azeite',
        'Coloque no pote com 250ml de água com acessório preto',
        'Coloque no modo de combo steam roast, 200 graus por 13 minutos',
        '(Opcional) Vire os vegetais e coloque por mais 2 minutos',
      ],
    },
  ],
};
